import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/layout"

export const data = graphql`
  {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      nodes {
        frontmatter {
          path
          title
          previewContent
          date(formatString: "DD-MMM-YYYY")
        }
      }
    }
  }
`

function chunk(arr, chunkSize) {
  var R = []
  for (var i = 0, len = arr.length; i < len; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize))
  return R
}

const BlogsPage = ({ props, data }) => {
  const blogPosts = data.allMarkdownRemark.nodes || []
  const postChunks = chunk(blogPosts, 2)
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="The HTML <form> element is one of most important element which we are missing most in the modern web development framework such as React, Angular, Vue, etc.,"
        />
        <title>Blog - Kalaivanan - Professional front-end/web engineer</title>
      </Helmet>
      <div className="about-me page">
        <h1>Blog</h1>
        <span className="break-line" />
        {postChunks.map(posts => (
          <div className="row blog-container">
            {posts.map(post => (
              <div
                key={post.frontmatter.title}
                className="col-sm-12 col-md-6 col-lg-6"
              >
                <div className="blog">
                  <div className="blog--content">
                    <Link to={post.frontmatter.path}>
                      <h3>{post.frontmatter.title}</h3>
                      <small>
                        <p>On {post.frontmatter.date}</p>
                      </small>
                      <p className="blog--content--preview">
                        {post.frontmatter.previewContent}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default BlogsPage
